<!--
@Author: laborde
@Date:   2022-03-23T09:33:58+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-01T14:02:02+02:00
-->

<template>
  <div>
    <topmenu></topmenu>
    <router-view/>
    <myfooter></myfooter>
  </div>
</template>

<script>

/////////////////// import ///////////////////

import topmenu from './components/topmenu'
import myfooter from './components/footer'

/////////////////// Vue ///////////////////

export default {
  name: 'mainview',
  data () {
    return {

    }
  },
  components: {
    topmenu,
    myfooter
  },
  computed: {
  },
  methods: {

  }
}


</script>

<style>

</style>
