/**
 * @Author: laborde
 * @Date:   2022-05-02T09:48:43+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-08-25T09:18:45+02:00
 */

export const en = {
  p: {

  },
  w: {
    cgu: "Terms"
  },
  ph: {
    partnership: "In partnership with "
  },
  btn: {
    signup: "Sign up",
    login: "Log in",
    get_started: "Get started"
  },
  home: {
    p1: "Hire Smart Beauty Insight,",
    p2: "Your CSR knowledge follower",
    p3: "All articles related to the major issues of the cosmetic industry selected by AI & human expertise",
    p4: "Pre-launch alpha free test version"
  },
  test: {
    t1: "STRATEGIC INTELLIGENCE",
    t2: "OPTIONAL PERSONALIZED MONITORING",
    t3: "READY TO SUBSCRIBE ?",
    p1: "Hire smartbeautyinsight,",
    p2: "All articles related to the major issues of the cosmetic industry selected by AI & human expertise",
    p3: "Create your account now",
    p4: "30 days free trial",
    p5: "Selection of professional information from more than 250,000 websites located in the USA, Asia, Europe, South America and Africa.",
    p6: "Themes: affection, biodiversity, climate change, clean beauty, distribution, water, formulation, green deal, majors, controversial ingredients, natural ingredients, artificial intelligence, sustainable investments, packaging, pollution, scientific publication, social media, tech/innovation, consumer trends, traceability.",
    p7: "Content in english categorized by key themes reviewed by our specialists.",
    p8: "Enrich your newsletters and LinkedIn feeds.",
    p9: "Information available on PC and smartphone.",
    p10: "Implementation of specific search algorithms on the themes you define in addition to the other themes covered in the premium subscription.",
    p11: "Premium monthly subscription of 149€/month excluding vat per user.",
    p12: "No commitment, can be cancelled at any time (month started due).",
    p13: "Create your account ",
    p14: "30 days free trial (no credit card info).",
    faq: {
      t: "Q&A",
      q1: "How is content selected ?",
      a1: "We use artificial intelligence to identify relevant content based on a variety of criteria. Our team of experts then performs an additional sort to ensure the quality of the data. The data is presented under several themes: affection, biodiversity, climate change, clean beauty, distribution, water, formulation, green deal, industrialists, controversial ingredients, natural ingredients, artificial intelligence, sustainable investments, packaging, pollution, scientific publication, social media, tech/innovation, consumer trends, traceability.",
      q2: "How much does the Smart Beauty Insight subscription cost ?",
      a2: "The premium subscription is the standard offer. Its cost is 149€/month excluding vat per user. This subscription can be completed by a customization to add themes of your choice. This customization is subject to an additional fee.",
      q3: "How can I stop my subscription ?",
      a3: "No complicated contract. No commitment. Two clicks are enough to delete your subscription. No cancellation fees.",
      q4: "How do I pay for my subscription ?",
      a4: "Payment is made by credit card via the secure stripe platform, which automatically debits your credit card each month. The registration of your credit card is done at the end of the trial period. If you wish to pay by bank transfer or check, you must commit to a minimum of 6 months of subscription. This manual processing requires that you contact us.",
      q5: "How to test Smart Beauty Insight ?",
      a5: "You benefit from 30 free days once your account is opened. You can end this free subscription at any time. You can also ask for an online demonstration.",
      q6: "Who are the customers ?",
      a6: "Smart beauty insight is aimed at all professionals in the cosmetics industry: manufacturers, brands, retailers, investment funds...",
    }
  }
}
