<!--
@Author: laborde
@Date:   2022-03-24T09:31:25+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-09T10:59:21+02:00
-->

<template>
  <div id="menu" class="d-flex align-items-center">

    <div class="normalScreenMenu">

      <div id="logo" class="d-flex" @click="goTo('home')">
        <img class="inverted" src="@/assets/img/png/logo.png" style="height:4.5em;cursor: pointer;">
      </div>

      <div id="logout" class="d-flex">
        <!-- <b-button @click="goTo('about')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('about') }]" tag="button">About</b-button> -->
        <b-button @click="goToSignup()" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('account') }]" tag="button">{{ $t('btn.signup') }}</b-button>
        <b-button @click="goToLogin()" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('account') }]" tag="button">{{ $t('btn.login') }}</b-button>
        <b-button @click="goTo('contact')" :class="['router','transparent_button','menu-button', { 'menu-button-active': activePage('contact') }]" tag="button">Contact</b-button>
      </div>
    </div>

    <div class="smallScreenMenu">

      <img v-b-toggle.sidebar-1 class="inverted ml-2" src="@/assets/img/svg/ico_menu.svg" style="height:2em;cursor: pointer;">

      <div id="logo" class="d-flex" @click="goTo('home')">
        <img class="inverted" src="@/assets/img/png/logo.png" style="height:4.5em;cursor: pointer;">
      </div>

      <b-sidebar id="sidebar-1" body-class="sidebarBodyClass" width="10em" no-header backdrop shadow>

        <div class="d-flex flex-column">
          <!-- <b-button @click="goTo('about')" :class="['router','transparent_button','menu-button-left', { 'menu-button-left-active': activePage('about') }]" tag="button">About</b-button> -->
          <b-button @click="goToSignup()" :class="['router','transparent_button','menu-button-left']" tag="button">{{ $t('btn.signup') }}</b-button>
          <b-button @click="goToLogin()" :class="['router','transparent_button','menu-button-left']" tag="button">{{ $t('btn.login') }}</b-button>
          <b-button @click="goTo('contact')" :class="['router','transparent_button','menu-button-left', { 'menu-button-left-active': activePage('contact') }]" tag="button">Contact</b-button>
        </div>

      </b-sidebar>
    </div>

  </div>


</template>

<script>

export default {
  name: 'topmenu',
  data () {
    return {
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    }
  },
  created: function() {
  },
  methods: {
    activePage(name){
      return this.routeName && this.routeName == name;
    },
    goTo(name){
      if(!this.routeName || this.routeName != name) this.$router.push({ name: name })
    },
    goToSignup(){
      window.open('https://app.smartbeautyinsight.com/app/signup', '_self');
    },
    goToLogin(){
      window.open('https://app.smartbeautyinsight.com/app/login', '_self');
    },
    logout () {
      window.$cookies.remove('access_token_sbapp');
      this.$router.push({ name: 'login' });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import '@/assets/css/common.scss';

#menu {
  height: 3.5em;
  width: 100%;
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-5;
  overflow-y: hidden;
  border: 1em;
}

.sidebarBodyClass {
  background-color: $color-5 !important;
}


.menu-button {
  color: $color-4;
  font-family: 'Raleway-SemiBold';
  margin-left:0.9em;
  margin-right:0.9em;
  padding-left:0.1em;
  padding-right:0.1em;

  &:hover:enabled, &:focus:enabled {
    color: $color-4-darker;
  }

}

.menu-button-active {
  color: $color-4-darker;
}

.menu-button-left {
  color: $color-4;
  font-family: 'Raleway-SemiBold';
  text-align: left;

  &:hover:enabled, &:focus:enabled {
    color: $color-4-darker;
  }

}

.menu-button-left-active {
  color: $color-4-darker;
}

#logout {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;

  button {
    color: white !important;
  }
}

#logo {
  position: absolute;
  height: 100%;
  left: calc(50% - 54px);
  top: -0.5em;
}

@media screen and (min-width: 700px) {
  .smallScreenMenu {
    display: none !important;
  }
}

@media screen and (max-width: 700px) {
  .normalScreenMenu {
    display: none !important;
  }
}

</style>
