<!--
@Author: laborde
@Date:   2022-03-24T09:31:25+01:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-03T14:12:03+02:00
-->

<template>
  <div id="myfooter">

    <div class="d-flex align-items-center h-100" style="position: relative;">


      <div id="mainTextFooter" class="d-flex justify-content-center align-items-center mx-auto">
        <span class="mr-2"><button id="cguButton" class="transparent_button" @click="showCGU = true">{{ $t('w.cgu') }}</button></span>
        <span>{{ $t('ph.partnership') }}L'Observatoire des Cosmétiques</span>
      </div>

      <div id="flags" class="d-flex ml-3">
        <b-button class="flagButton p-0 mr-2" @click="switchLanguage('en')" title="Chose English">
          <img src="@/assets/img/svg/ico_english_flag.svg" :class="(language == 'en')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
        <b-button class="flagButton p-0" @click="switchLanguage('fr')" title="Chose French">
          <img src="@/assets/img/svg/ico_french_flag.svg" :class="(language == 'fr')? 'flagButtonEnable' : 'flagButtonDisabled'" style="height:1.3em" />
        </b-button>
      </div>

    </div>

    <b-modal hide-header hide-footer size="xl" v-model="showCGU">
      <b-button class="crossButton" @click="showCGU = false">✕</b-button>

      <cguComp></cguComp>

    </b-modal>

  </div>
</template>

<script>

import cguComp from './cgu'

export default {
  name: 'footer',
  data () {
    return {
      showCGU: false,
      language: 'en'
    }
  },
  components: {
    cguComp
  },
  created: function() {
    this.language = this.$i18n.locale;
  },
  methods: {
    switchLanguage(lang){
      // if language already good
      if(lang == this.$i18n.locale) return;
      this.language = lang;
      this.$cookies.set('language', this.language, -1);
      this.$i18n.locale = this.language
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import '@/assets/css/common.scss';

#myfooter {
  height: 2em;
  width: 100%;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-5;
  overflow-y: hidden;
  overflow-x: hidden;
  border: 1em;
}

#mainTextFooter {
  font-size: 0.8em;
  text-align: center;
  color: white;
  width: 70%;

  line-height: 1em;

  a {
    color: white;
    text-decoration: underline;
  }
}

#flags {
  right: 0.5em;
  position: absolute;
}

.flagButton {
  border: none;
  background-color: Transparent !important;
  box-shadow: none !important;

  &:active, &:active:hover {
    background: none;
  }
}

.flagButtonEnable {
  filter: brightness(1.5);
  filter: contrast(100%);
  cursor: default;
}

.flagButtonDisabled {
  filter: brightness(1);
  filter: contrast(70%);
}

#cguButton {
  color: white;
  text-decoration: underline;
};

</style>
