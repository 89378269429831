<!--
@Author: laborde
@Date:   2022-05-31T12:04:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-06-02T09:12:27+02:00
-->

<template>
  <div id="main">
    <div class="d-flex">
      <div class="d-flex align-items-center mx-auto mt-3">
        <img class="mr-2" src="@/assets/img/png/ico_email.png" style="height:2em">
        <a id="emailLink" href="mailto:contact@smartbeautyinsight.com">contact@smartbeautyinsight.com</a>
      </div>
    </div>
  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'Contact',
  data () {
    return {

    }
  },
  created: function() {

  },
  methods: {

  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';

#emailLink {
  color: $color-5;
}


</style>
