<!--
@Author: laborde
@Date:   2022-05-31T12:04:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-08-18T09:26:09+02:00
-->

<template>
  <div id="main">

    <div id="mainDiv" class="d-flex flex-column mx-auto pb-5">


      <h3 id="text1" class="mx-auto mb-4">{{ $t('test.p1') }}</h3>

      <div id="text2">{{ $t('test.p2') }}</div>

      <b-button id="starterButton" class="largeColoredButton mx-auto" @click="goToSignup()">{{ $t('test.p3') }}</b-button>

      <div id="text3">{{ $t('test.p4') }}</div>

      <hr>

      <h3 class="subtitle">{{ $t('test.t1') }}</h3>

      <div class="textPres mb-3 mx-auto">{{ $t('test.p5') }}</div>
      <div class="textPres mb-3 mx-auto">{{ $t('test.p6') }}</div>
      <div class="textPres mb-3 mx-auto">{{ $t('test.p7') }}</div>
      <div class="textPres mb-3 mx-auto">{{ $t('test.p8') }}</div>
      <div class="textPres mb-3 mx-auto">{{ $t('test.p9') }}</div>

      <div v-if="language == 'fr'" id="screenshots">
        <img class="mr-2" src="@/assets/img/png/screen_home_smartphone.fr.png" style="height:10em">
        <img src="@/assets/img/png/screen_themes_tab.fr.png" style="height:10em">
        <img src="@/assets/img/png/screen_home_laptop.fr.png" style="height:15em">
      </div>
      <div v-else id="screenshots">
        <img class="mr-2" src="@/assets/img/png/screen_home_smartphone.en.png" style="height:10em">
        <img src="@/assets/img/png/screen_themes_tab.en.png" style="height:10em">
        <img src="@/assets/img/png/screen_home_laptop.en.png" style="height:15em">
      </div>


      <!-- <div id="screenshots2">
        <img id="smartphone" src="@/assets/img/png/screen_home_smartphone.png" style="height:8em">
        <img id="tab" src="@/assets/img/png/screen_home_tab.png" style="height:10em">
        <img id="laptop" src="@/assets/img/png/screen_home_laptop.png" style="height:15em">
      </div> -->

      <hr>

      <h3 class="subtitle">{{ $t('test.t2') }}</h3>

      <div class="textPres mb-3 mx-auto">{{ $t('test.p10') }}</div>


      <hr>

      <h3 class="subtitle">{{ $t('test.t3') }}</h3>

      <div class="textPres mb-1 mx-auto">{{ $t('test.p11') }}</div>

      <div class="textPres mb-3 mx-auto">{{ $t('test.p12') }}</div>

      <b-button id="starterButton" class="largeColoredButton mx-auto" @click="goToSignup()">{{ $t('test.p13') }}</b-button>
      <div id="text3">{{ $t('test.p14') }}</div>

      <hr>

      <h3 class="subtitle mb-0">{{ $t('test.faq.t') }}</h3>

      <h4 class="question">{{ $t('test.faq.q1') }}</h4>
      <div class="response mb-3 mx-auto">{{ $t('test.faq.a1') }}</div>
      <h4 class="question">{{ $t('test.faq.q2') }}</h4>
      <div class="response mb-3 mx-auto">{{ $t('test.faq.a2') }}</div>
      <h4 class="question">{{ $t('test.faq.q3') }}</h4>
      <div class="response mb-3 mx-auto">{{ $t('test.faq.a3') }}</div>
      <h4 class="question">{{ $t('test.faq.q4') }}</h4>
      <div class="response mb-3 mx-auto">{{ $t('test.faq.a4') }}</div>
      <h4 class="question">{{ $t('test.faq.q5') }}</h4>
      <div class="response mb-3 mx-auto">{{ $t('test.faq.a5') }}</div>
      <h4 class="question">{{ $t('test.faq.q6') }}</h4>
      <div class="response mb-3 mx-auto">{{ $t('test.faq.a6') }}</div>

    </div>



  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'Home',
  data () {
    return {

    }
  },
  computed: {
    language: function () {
      return this.$i18n.locale || 'en';
    }
  },
  created: function() {

  },
  methods: {
    goToSignup(){
      window.open('https://app.smartbeautyinsight.com/app/signup', '_self');
    },
  }
}

</script>

<style lang="scss" scoped>

@import '@/assets/css/common.scss';

hr {
  width: 80%;
}

#main {
  text-align: justify;
  font-family: Calibri, sans-serif;
}

#mainDiv {
  width: 75%;
}

#text1 {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  margin-top: 8em;
  color: $color-5;
  width: 80%;
  margin: 1em auto 1em auto;

}

#text2 {
  font-size: 2.8em;
  font-weight: bolder;
  // text-transform: uppercase;
  text-align: center;
  // color: $color-5;
  width: 80%;
  margin: 0 auto 1em auto;
  background: linear-gradient(to right, $color-5, $color-5-light);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

#text3 {
  font-size: 1em;
  text-align: center;
}

.textPres {
  width: 80%;
  text-align: center;
}

.subtitle {
  // font-size: 1.8em;
  font-weight: bolder;
  text-align: center;
  color: $color-5;
  margin-bottom: 0.5em;
  margin-top: 1em;
}

#screenshots {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 75% !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  flex-wrap: wrap !important
}

#screenshots2 {
  position: relative;
  width: 70%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 1em;

  #smartphone {
    position: absolute;
    bottom: -1em;
    left: 0;
  }

  #tab {
    position: absolute;
    bottom: -1em;
    left: 13em;
  }
}

.question {
  font-weight: bolder;
  text-align: center;
  color: $color-5;
  margin-bottom: 0.5em;
  margin-top: 1em;
}

.response {
  width: 80%;
}

#text4 {
  font-size: 1em;
  font-weight: lighter;
  text-align: center;
  width: 50%;

}

#starterButton {
  background-color: $color-5;
  border-radius: 3px;
}

@media screen and (max-width: 700px) {
  hr {
    width: 95%;
  }

  #mainDiv {
    width: 95%;
  }

  #text1 {
    font-size: 1em;
    width: 95%;
  }

  #text2 {
    font-size: 2.3em;
    width: 95%;
  }


  #text3 {
    font-size: 1em;
  }

  .textPres {
    width: 95%;
  }

  #screenshots {
    width: 100% !important;
    align-items: center !important;
    // flex-direction: column !important;
  }
  .question {
    width: 95%;
  }

  .response {
    width: 95%;
  }
}


</style>
