/**
 * @Author: laborde
 * @Date:   2022-05-31T12:04:23+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-08-18T09:26:30+02:00
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '@/components/home.vue'
import contact from '@/components/contact.vue'
import about from '@/components/about.vue'
import buildScreen from '@/components/buildScreen.vue'

Vue.use(VueRouter)

var _path = '/app';

const routes = [
  {
    path: _path + '/home',
    alias: _path,
    name: 'home',
    component: home,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: _path + '/buildScreen',
    name: 'buildScreen',
    component: buildScreen,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: _path + '/contact',
    name: 'contact',
    component: contact,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: _path + '/about',
    name: 'about',
    component: about,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '*',
    redirect: _path
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
