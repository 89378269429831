<!--
@Author: laborde
@Date:   2022-05-31T12:04:23+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-21T10:23:05+02:00
-->

<template>
  <div id="main" class="p-5">

      <!-- FR -->

      <!-- <div id="screenshot_smartphone" class="mx-auto">
        <img id="phone" src="@/assets/img/png/mockups/smartphone.png">
        <img id="screen" src="@/assets/img/png/screenshots/smartphone.fr.png">
      </div> -->

      <!-- <div id="screenshot_laptop" class="mx-auto pr-3">
        <img id="laptop" src="@/assets/img/png/mockups/laptop.png">
        <img id="screen" src="@/assets/img/png/screenshots/laptop.fr.png">
      </div> -->

      <!-- <div id="screenshot_tab" class="mx-auto">
        <img id="tab" src="@/assets/img/png/mockups/tab.png">
        <img id="screen" src="@/assets/img/png/screenshots/tab.fr.png">
      </div> -->

      <!-- EN -->

      <!-- <div id="screenshot_smartphone" class="mx-auto">
        <img id="phone" src="@/assets/img/png/mockups/smartphone.png">
        <img id="screen" src="@/assets/img/png/screenshots/smartphone.en.png">
      </div> -->

      <!-- <div id="screenshot_laptop" class="mx-auto pr-3">
        <img id="laptop" src="@/assets/img/png/mockups/laptop.png">
        <img id="screen" src="@/assets/img/png/screenshots/laptop.en.png">
      </div> -->

      <!-- <div id="screenshot_tab" class="mx-auto">
        <img id="tab" src="@/assets/img/png/mockups/tab.png">
        <img id="screen" src="@/assets/img/png/screenshots/tab.en.png">
      </div> -->


  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'Home',
  data () {
    return {

    }
  },
  created: function() {

  },
  methods: {

  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';


</style>
