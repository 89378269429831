<!--
@Author: laborde
@Date:   2022-07-13T14:00:22+02:00
@Email:  qlaborde@beautylitic.com
@Last modified by:   laborde
@Last modified time: 2022-07-15T10:44:30+02:00
-->

<template>
  <div>

    <h3 align="center">TERMS OF SMARTBEAUTYINSIGHT SERVICE</h3>

    <div class="mb-3">
      The following terms and conditions govern all use of smartbeautyinsight.com (Website or Smart Beauty Insight) and all content, services and products available at or through the Website. The Website is owned and operated by LNEC SAS (Company), a company operating in France. The Website is offered subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies and procedures that may be published from time to time on Smart Beauty Insight website (collectively, the "Agreement").
      Please read this Agreement carefully before accessing or using the Website. By accessing or using any part of the web site, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access the Website or use any services it provides.
    </div>

    <h4>YOUR SMART BEAUTY INSIGHT ACCOUNT AND SITE</h4>
    <div class="mb-3">
      If you create an account on the Website, you are responsible for maintaining the security of your account, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You must immediately notify the Company of any unauthorized uses of your account or any other breaches of security. Smart Beauty Insight will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.
      By creating an account on Smart Beauty Insight you give us permission to send you an activation email as well as a small number of drip emails that will provide you an introduction on how to use different features of Smart Beauty Insight.
    </div>

    <h4>FREE ACCESS</h4>
    <div class="mb-3">
      Smart Beauty Insight provides new users a limited access to the Website to validate suitability. The users of the free access should not try to overcome the limit by creating multiple accounts or accessing the service through different IP addresses.
    </div>

    <h4>PAID PLAN</h4>
    <div class="mb-3">
      <div class="mb-2"><strong>General Terms.</strong> To remove the restrictions of the free account, the users have to subscribe to a paid plan. By subscribing to a monthly plan you agree to pay the Company the monthly fee for using the service.</div>
      <div class="mb-2"><strong>Automatic Renewal.</strong> Unless you unsubscribe, your subscription will be automatically renewed and you authorize us to collect the applicable monthly fee for the service using the credit card or other payment mechanism we have on record for you. Once you unsubscribe, you can still use the service until the end of the month. The company will not issue a refund for the current or any previous months.</div>
      <div class="mb-2"><strong>Fees.</strong> The Company reserves the right to change the payment terms and fees upon thirty (30) days after a written notice to you.</div>
      <div class="mb-2"><strong>Distribution & paid plan invoicing.</strong> The paid plan can be invoiced by an affiliate distributor of the Smart Beauty Insight service as L’Observatoire des Cosmétiques SARL.</div>
      <div class="mb-2"><strong>Online payment system.</strong> Stripe.com is the on-line payment system.</div>
    </div>

    <h4>THIRD PARTY CONTENT</h4>
    <div class="mb-3">
      The data provided to you will include content from third parties (Third Party Content). Since the data is collected automatically by Smart Beauty Insight and provided to you automatically based on search requests, Smart Beauty Insight makes no warranties or representations with respect to the Third Party Content, including with respect to its legality, non-infringement, offensiveness, ownership and content or the right to use the Third Party Content.
      Smart Beauty Insight shall in no way or manner be responsible for the accuracy of Third Party Content, specifically including the results returned by the search service, and you rely on the accuracy of such content solely at your own risk.
    </div>

    <h4>DATA RESTRICTIONS</h4>
    <div class="mb-3">
      <div class="mb-2">
        Provision of Smart Beauty Insight data is upon condition of proper use. Except as expressly authorized under this agreement, customer may not :
      </div><ul>
        <li>Copy, rent, lease, sell, transfer, assign, sublicense, disassemble, reverse engineer, create derivative works, decompile, modify, adapt or alter any part of the service we provided;</li>
        <li>Create multiple accounts with the purpose of free data access;</li>
        <li>Use Smart Beauty Insight to reproduce copyrighted material;</li>
        <li>Use Smart Beauty Insight in any manner that violates any laws directly or indirectly.</li>
      </ul>
    </div>

    <h4>DATA LICENSE</h4>
    <div class="mb-3">
      As part of Smart Beauty Insight, the Company collects and generates certain data by aggregating information regarding publicly available web pages, utilizing Company’s proprietary technology. This data does not contain any personally identifiable information regarding any end users. As part of the Service, certain data is made publicly available to end-users.
      Data accessible through Smart Beauty Insight may contain Third Party Content (such as text, images, videos obtained from various news sources). This content will remain the sole responsibility of those who make it available. In some cases content accessible through our Services may also be subject to intellectual property rights. In these cases you are allowed only to perform actions and activities that are awarded to you by the owner of the content.
    </div>

    <h4>COPYRIGHT INFRINGEMENT AND DMCA POLICY</h4>
    <div class="mb-3">
      As Company asks others to respect its intellectual property rights, it respects the intellectual property rights of others. If you believe that material located on or linked to by Service violates your copyright, you are encouraged to notify Company in accordance with Digital Millennium Copyright Act ("DMCA") Policy. The Company will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material.
    </div>

    <h4>INTELLECTUAL PROPERTY</h4>
    <div class="mb-3">
      Other than Third Party Content, Smart Beauty Insight will solely and exclusively own all intellectual property and other rights, title and interest in and to the Website, the services and the data. Smart Beauty Insight will also have the sole and exclusive right to obtain trademark and copyright registrations, patents and other protection therefor and for any continuations and derivative works thereof. You will not, by use of the Services or accessing the Website, acquire any right, title or interest in any rights of Smart Beauty Insight under these Terms or otherwise.
    </div>

    <h4>ADVERTISEMENTS</h4>
    <div class="mb-3">
      The Company reserves the right to display advertisements on its Website.
    </div>

    <h4>CHANGES</h4>
    <div class="mb-3">
      The Company reserves the right, at its sole discretion, to modify or replace any part of this Agreement at any time without notice. It is your duty to read this page regularly. The Company may also, in the future, offer new services and/or features through the Website (including, the release of new tools and resources). Such new features and/or services shall be subject to the Terms of this Agreement.</p>
    </div>

    <h4>TERMINATION</h4>
    <div class="mb-3">
      If you wish to terminate this Agreement or your Smart Beauty Insight account (if you have one), you may simply discontinue using the Website. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. Abuse of free accounts, either by registering many accounts with very similar user names, or by using a disposable email address, shall be grounds for immediate termination of your access without notice.
    </div>

    <h4>DISCLAIMER OF WARRANTIES</h4>
    <div class="mb-3">
      The Website is provided "as is". Company and its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Company nor its suppliers and licensors, makes any warranty that the Website will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Website at your own discretion and risk. Company will ensure that Smart Beauty Insight service will operate with >99% up-time.
    </div>

    <h4>LIMITATION OF LIABILITY</h4>
    <div class="mb-3">
      Notwithstanding anything herein to the contrary, Smart Beauty Insight's, including its officers, directors, shareholders, employees, sub-contractors, agents and affiliates, sole and cumulative liability to you or to any third party for any loss, cost or damage resulting from any claims, demands or actions arising out of, or relating to, these Terms and/or your use of the Website and/or the Services shall not exceed the consideration actually paid by you to Smart Beauty Insight for the services during the three (3) months immediately preceding the occurrence of the event which gave rise to the applicable loss, cost or damage. The provisions of this section allocate risks under these terms between you and Smart Beauty Insight. Smart Beauty Insight’s pricing of the Services hereunder reflect this allocation of risks and limitation of liability.
      In no event shall Smart Beauty Insight be liable for any indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to, damages for loss of profits, revenue, goodwill, data, electronically transmitted requests or other economic advantage (even if Smart Beauty Insight has been advised of the possibility of such damages), however caused and regardless of the theory of liability, whether in contract (including fundamental breach), tort (including negligence) or otherwise, arising out of or related to the use of or the inability to use or the reliance upon the information displayed by the Website and/or the Services for any reason. Some states may not allow the limitation or exclusion of liability for indirect, incidental or consequential damages, so the above limitation or exclusion may not apply to you.
    </div>

    <h4>GENERAL REPRESENTATION AND WARRANTY</h4>
    <div class="mb-3">
      You represent and warrant that (i) your use of the Website will be in strict accordance with this Agreement and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the country in which you reside) and (ii) your use of the Website will not infringe or misappropriate the intellectual property rights of any third party.
    </div>

    <h4>INDEMNIFICATION</h4>
    <div class="mb-3">
      You agree to indemnify and hold harmless Company, its contractors, and its licensors, and their respective directors, officers, employees and agents from and against any and all claims and expenses, including attorneys' fees, arising out of your use of the Website, including but not limited to your violation of this Agreement.
    </div>
  </div>
</template>

<script>

////////////////// Import //////////////////

////////////////// Functions //////////////////

export default {
  name: 'cgu',
  data () {
    return {

    }
  },
  created: function() {

  },
  methods: {

  }
}

</script>

<style lang="scss">

@import '@/assets/css/common.scss';


</style>
