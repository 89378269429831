/**
 * @Author: laborde
 * @Date:   2022-05-02T09:48:43+02:00
 * @Email:  qlaborde@beautylitic.com
 * @Last modified by:   laborde
 * @Last modified time: 2022-08-25T09:18:22+02:00
 */

export const fr = {
  p: {

  },
  w: {
    cgu: "CGU"
  },
  ph: {
    partnership: "En partenariat avec "
  },
  btn: {
    signup: "S'inscrire",
    login: "Se connecter",
    get_started: "Get started"
  },
  home: {
    p1: "Engagez Smart Beauty Insight,",
    p2: "Votre follower des connaissances RSE",
    p3: "Tous les articles relatifs aux grands enjeux de l’industrie cosmétique sélectionnés par l’IA & l’expertise humaine",
    p4: "Pré-lancement version gratuite test alpha"
  },
  test: {
    t1: "VEILLE STRATÉGIQUE",
    t2: "VEILLE PERSONNALISÉE EN OPTION",
    t3: "PRÊT POUR VOUS ABONNER ?",
    p1: "Engagez Smart Beauty Insight,",
    p2: "Tous les articles relatifs aux grands enjeux de l’industrie cosmétique sélectionnés par l’IA & l’expertise humaine",
    p3: "Creer votre compte maintenant",
    p4: "30 jours d’essai gratuit",
    p5: "Sélection des informations professionnelles à partir de plus de 250000 sites internet situés aux USA, Asie, Europe, Amérique du Sud, Afrique.",
    p6: "Thèmes : Affection, biodiversité, changement climatique, Clean Beauty, distribution, eau, formulation, Green Deal, majors, ingrédients controversés, ingrédients naturels, intelligence artificielle, investissements durables, packaging, pollution, publication scientifique, social media, tech/innovation, tendances conso, traçabilité.",
    p7: "Contenus en anglais catégorisés par thèmes clés revus par nos spécialistes.",
    p8: "Enrichissez vos newsletters et vos flux Linkedin.",
    p9: "Informations consultables sur PC et Smartphone.",
    p10: "Mise en place d’algorithmes de recherches spécifiques sur le ou les thèmes que vous définissez en complément des autres thèmes traités au sein de l’abonnement Premium.",
    p11: "Abonnement mensuel premium de 149€/mois HT.",
    p12: "Sans engagement, annulable à tout moment (mois commencé dû)",
    p13: "Creer votre compte",
    p14: "30 jours d’essai gratuit (sans info CB)",
    faq: {
      t: "FAQ",
      q1: "Comment les contenus sont-ils sélectionnés ?",
      a1: "Nous avons recours à l’intelligence artificielle pour identifier les contenus pertinents selon une multitude de critères. Notre équipe d’experts effectue ensuite un tri complémentaire pour s’assurer de la qualité des données. Les données sont restitués sous plusieurs thèmes: Affection, biodiversité, changement climatique, Clean Beauty, distribution, eau, formulation, Green Deal, majors, ingrédients controversés, ingrédients naturels, intelligence artificielle, investissements durables, packaging, pollution, publication scientifique, social media, tech/innovation, tendances conso, traçabilité.",
      q2: "Combien coûte l’abonnement Smart Beauty Insight ?",
      a2: "L’abonnement premium est l’offre standard. Son coût est de 149€/mois HT par utilisateur. Cet abonnement peut être complété par une personnalisation pour ajouter des thèmes de votre choix. Cette personnalisation fait l’objet d’un montant complémentaire.",
      q3: "Comment puis-je arrêter mon abonnement ?",
      a3: "Pas de contrat compliqué. Sans engagement. Deux clics suffisent pour supprimer votre abonnement. Pas de frais d’annulation.",
      q4: "Comment payer mon abonnement ?",
      a4: "Le règlement se fait par carte bancaire via la plateforme sécurisée Stripe qui se charge du débit automatique de votre carte bancaire chaque mois. L’enregistrement de votre carte bancaire se fait à l’issue de la période d’essai. Si vous souhaitez régler par virement ou chèque, vous devez vous engager sur 6 mois d’abonnement minimum. Ce traitement manuel nécessite que vous nous contactiez.",
      q5: "Comment tester Smart Beauty Insight ?",
      a5: "Vous bénéficiez de 30 jours gratuits une fois votre compte ouvert. Vous pouvez mettre fin à cet abonnement gratuit à n’importe quel moment. Vous pouvez aussi demander une démonstration en ligne.",
      q6: "Qui sont les clients ?",
      a6: "Smart Beauty Insight s’adresse à tous les professionnels de l’industrie cosmétique : fabricants, marques, retailers, fonds d’investissements…"
    }
  }
}
